<template>
    <div class="index" @click="indexBodyClick">
        <Header />
        <What ref="what" />
        <With ref="with" />
        <!-- <More /> -->
        <Footer />
        <!-- 弹窗 -->
        <div class="popup" v-if="popupShow" @click="popupHide">
            <div class="popup_body" @click.stop>
                <div class="close" @click="popupHide"></div>
                <div class="title">{{title}}</div>
                <div class="ma">
                    <img class="img" :src="ma" alt />
                </div>
                <div class="word">{{word}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "@/components/Header.vue";
import What from "@/components/What.vue";
import With from "@/components/With.vue";
// import More from "@/components/More.vue";
import Footer from "@/components/Footer.vue";

export default {
    name: "index",
    components: {
        Header,
        What,
        With,
        // More,
        Footer
    },
    data() {
        return {
            // 弹框显示
            popupShow: false,
            title: "下载APP",
            ma: require("../assets/images/ma.png"),
            word: "扫描下载阿拉丁中文网APP"
        };
    },
    methods: {
        indexBodyClick() {
            if (this.$refs.what.show) {
                this.$refs.what.showHide();
            }
            if (this.$refs.with.show || this.$refs.with.show2) {
                this.$refs.with.showHide();
            }
        },
        popupHide() {
            this.popupShow = false;
        },
        popupFun(index) {
            if (index == 2) {
                this.title = "下载APP";
                this.ma = require("../assets/images/ma.png");
                this.word = "扫描下载阿拉丁中文网APP";
            } else if (index == 3) {
                this.title = "阿拉丁小程序";
                this.ma = require("../assets/images/applet.png");
                this.word = "微信扫描进入阿拉丁小程序";
            }
            this.popupShow = true;
        }
    }
};
</script>
<style scoped>
.popup {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
}
.popup_body {
    width: 470px;
    height: 473px;
    background: #ffffff;
    border-radius: 10px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding-top: 35px;
}
.popup_body .title {
    font-size: 24px;
    color: #222222;
}
.popup_body .close {
    width: 32px;
    height: 32px;
    background: url(../assets/images/close.png) no-repeat center;
    background-size: 32px;
    cursor: pointer;
    position: absolute;
    top: 35px;
    right: 49px;
}
.popup_body .ma {
    width: 168px;
    height: 168px;
    margin: 0 auto;
    margin-top: 50px;
}
.popup_body .ma .img {
    width: 100%;
}
.popup_body .word {
    font-size: 24px;
    margin-top: 65px;
}
</style>
